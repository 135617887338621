import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const MainContent = ({ userData }) => {
  return (
    <div className='container'>
    <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" style={{marginTop:'70px'}}>
      <Card className="bg-white shadow-lg">
        <CardContent>
          <Typography variant="h5">Inscriptos</Typography>
          <GroupsIcon></GroupsIcon>
          <Typography variant="h4" className="text-green-500">1.500</Typography>
        </CardContent>
      </Card>

      <Card className="bg-white shadow-lg">
        <CardContent>
          <Typography variant="h5">Recaudación</Typography>
          <AttachMoneyIcon></AttachMoneyIcon>
          <Typography variant="h4" className="text-blue-500">$45,000</Typography>
        </CardContent>
      </Card>

      <Card className="bg-white shadow-lg">
        <CardContent>
          <Typography variant="h5">Inscriptos por distancia</Typography>
          <Typography variant="h4" className="text-red-500">350</Typography>
        </CardContent>
      </Card>
    </div>
    <div className='p-6'>
    <Card className="bg-white shadow-lg">  
      <Typography variant="h5">Inscriptos por distancia</Typography>  
      <LineChart
         xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
         series={[
          {
           data: [2, 5.5, 2, 8.5, 1.5, 5],
          },
        ]}
         width={500}
         height={300}
        />
    </Card>
    </div>
    </div>
  );
}

export default MainContent;