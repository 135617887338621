import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import Sidebar from '../User/Sidebar';
import Header from '../User/Header';
import MainContent from './MainContent';
import InscriptosList from './InscriptosList';
import DownloadExcel from './DownloadExcel';
import { useLocation } from 'react-router-dom';

export default function UserDash() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('MainContent'); // Estado para manejar el componente activo
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation(); // Obtener la ubicación
  const userId = location.state?.userId; // Obtener el userId pasado desde LoginUser
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (userId) {
      // Lógica para obtener datos del usuario de la base de datos usando el userId
      fetchUserData(userId);
    }
  }, [userId]);

  const fetchUserData = async (userId) => {
    // Aquí iría la lógica para obtener datos del usuario desde tu API o base de datos
    try {
      const response = await fetch(`/api/user/${userId}`); // Reemplaza con tu endpoint real
      const data = await response.json();
      setUserData(data); // Guardar los datos del usuario en el estado
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {(!isMobile || sidebarOpen) && (
        <Sidebar onComponentChange={handleComponentChange} closeSidebar={toggleSidebar} />
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Header toggleSidebar={toggleSidebar} />
        {activeComponent === 'MainContent' && <MainContent userData={userData} />}
        {activeComponent === 'InscriptosList' && <InscriptosList userData={userData} />}
        {activeComponent === 'DownloadExcel' && <DownloadExcel userData={userData} />}
        <Toolbar />
      </Box>
    </Box>
  );
}
