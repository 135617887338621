import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DownloadExcel = () => {
  const handleDownload = () => {
    // Aqui debo poner las rutas para que me traiga los datos de la bd
    const data = [
      { Name: 'John Doe', Age: 28, City: 'New York' },
      { Name: 'Jane Smith', Age: 34, City: 'San Francisco' },
    ];

    const ws = XLSX.utils.json_to_sheet(data); //En data va el nombre del archivo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
  };

  return (
    <div className='container' style={{marginTop: '70px'}}>
    <Button variant="contained" color="error" onClick={handleDownload}>
      Descargar Excel
    </Button>
    </div>
  );
};

export default DownloadExcel;
