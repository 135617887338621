import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import img1 from '../../../images/ImgInscripciones/ValleLuna.jpg';

const DesafioValleDeLaLuna2025 = () => {
const navigate = useNavigate();
const raceName = 'Desafio Valle De La Luna 2025'

const handleInscribirse = (raceName, distance, price, image) => {
  const numericPrice = parseFloat(price.replace(/\./g, '').replace(',', '.')); // Convierte '20.000,00' a 20000.00
      // Redirige al componente Forms con los datos seleccionados
      navigate(`/${raceName}/forms`, { state: { distance, price: numericPrice, image }, });
    };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 4,
        borderRadius: 2,
        maxWidth: '90%',
        margin: 'auto',
        marginTop: 4,
        marginBottom: 4,
        fontFamily: 'Arial, sans-serif',
        boxShadow: '0px 1px 20px rgba(100, 0, 0, 100)',
      }}
    >
      {/* Banner Image */}
      <Box
        component="img"
        src={img1}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
        }}
      />

<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          Información de la Carrera
        </Typography>
        <Typography variant="body1">
          Aquí puedes agregar la información detallada sobre la carrera, como la fecha, lugar,
          horarios, etc.
        </Typography>
        <br></br>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          Reglamento
        </Typography>
        <Typography variant="body1">
          Aquí puedes cargar o detallar el reglamento de la carrera, incluyendo cualquier
          normativa que los participantes deban seguir.
        </Typography>
        <br></br>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
          Inscripciones
        </Typography>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  5K
                </Typography>
                <Typography variant="h5">
                  $1.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse(raceName, '5K', '1.000,00', img1)}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  10K
                </Typography>
                <Typography variant="h5">
                  $30.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse(raceName, '10K', '30.000,00', img1)}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  21K
                </Typography>
                <Typography variant="h5">
                  $40.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse(raceName, '21K', '40.000,00', img1)}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              variant='outlined'
              sx={{
                maxWidth: 300,
                transition: "0.2s",
                boxShadow: "0px 1px 20px rgba(100, 0, 0, 100)",
                borderRadius: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                }
              }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  42K
                </Typography>
                <Typography variant="h5">
                  $50.000,00
                </Typography>
              </CardContent>
              <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleInscribirse(raceName, '42K', '50.000,00', img1)}
                >
                  Inscribirse
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <br></br>
        
      
    </Box>
  );
};

export default DesafioValleDeLaLuna2025;
