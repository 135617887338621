import React, { useState } from 'react';
import { TextField, Box, Typography, Grid, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate para la redirección
import LogoInscripciones from '../../../images/EDI.png';
import appFirebase from '../../../credenciales';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const auth = getAuth(appFirebase);

const LoginUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Crear una instancia de useNavigate

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    try {
      // Autenticación con Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Obtiene el objeto del usuario
      const userId = user.uid; // Obtiene el UID del usuario
      // Si la autenticación es exitosa, redirigir a UserDash
      navigate('/userdash', { state: { userId } }); // Pasando el UID como estado
    } catch (error) {
      // Mostrar error si ocurre algún problema
      setError('Correo o contraseña incorrectos');
    }
  };

  return (
    <div className="white-background">
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          padding: { xs: 2, md: 4 },
          maxWidth: '100%',
          margin: 'auto',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 4,
          }}
        >
          <Box
            component="img"
            src={LogoInscripciones}
            alt="logo"
            sx={{
              width: { xs: '60%', md: '15%' },
              borderRadius: 2,
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#f5f5f5',
              padding: { xs: 2, md: 4 },
              borderRadius: 2,
              maxWidth: '100%',
              fontFamily: 'Arial, sans-serif',
              boxShadow: '0px 1px 10px black',
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: 'bold', marginBottom: 3, color: 'grey' }}
            >
              Ingresa a tu evento
            </Typography>

            {/* Mostrar error si ocurre */}
            {error && (
              <Typography color="error" variant="body2" sx={{ marginBottom: 2 }}>
                {error}
              </Typography>
            )}

            {/* Contenedor del Grid */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  id="email"
                  label="Correo Electrónico"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} marginTop={3}>
              <TextField
                fullWidth
                required
                id="password"
                label="Contraseña"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiInputLabel-root': { color: '#333' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'red',
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Button
              variant="contained"
              color="error"
              fullWidth
              sx={{ marginTop: 3 }}
              onClick={handleLogin} // Llamar a la función para manejar el inicio de sesión
            >
              Ingresar
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LoginUser;
