import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faSquareFacebook, faWhatsapp} from '@fortawesome/free-brands-svg-icons';


const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="container">
        <span>Email: informes@esfuerzodeportivo.com</span>
        
        <ul className='btn-redes' type='button'>
            <a href="http://www.facebook.com/esfuerzo.deportivo.1/">
              <FontAwesomeIcon icon={faSquareFacebook} style={{marginRight:20}} color='white'/>
            </a>
            <a href="https://www.instagram.com/esfuerzo.deportivo/">
              <FontAwesomeIcon icon={faInstagram} style={{marginRight:20}} color='white'/>
            </a>
            <a href="https://wa.me/2604599048">
            <FontAwesomeIcon icon={faWhatsapp}  color='white'/>
            </a>
        </ul>
      
      </div>
    </div>
  );
};

export default TopBar;
