import React, { useState, useEffect } from 'react';
import axios from "axios"
import config from '../../config/config'

const UpExcel = () => {

/**Traer tablas de la db */
const [tablas, setTablas] = useState(['']);
const [nombreTabla, setNombreTabla] = useState("");

useEffect(() => {
  const obtenerTablas = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/clasificaciones`);
      setTablas(response.data);
    } catch (error) {
      console.error('Error al obtener las tablas:', error);
    }
  };
  obtenerTablas();
}, []);

/**Subir excel a la tabla*/
const [archivo, setArchivo] = useState(null);

const handleArchivoChange = (event) => {
setArchivo(event.target.files[0]);
};

const handleNombreTablaChange = (event) => {
    setNombreTabla(event.target.value);
  };

const handleFormSubmit = async (event) => {
event.preventDefault();
const formData = new FormData();
formData.append('archivoExcel', archivo);
formData.append('nombreTabla', nombreTabla);

try {
  const response = await axios.post(`${config.API_URL}/cargarArchivo`, formData, {
    nombreTabla,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  alert(response.data);
} catch (error) {
  alert('Error al cargar el archivo');
}
};

const handleEliminarDatos = async () => {
  try {
    const response = await axios.delete(`${config.API_URL}/eliminarDatos`, { data: { nombreTabla } });
    alert(response.data);
  } catch (error) {
    alert('Error al eliminar los datos');
  }
};

  return (
    <div className='container'>

      <form className='formin-line' onSubmit={handleFormSubmit}>
    <label className="my-1 mr-2 text-light" for="inlineFormCustomSelectPref">
        Selecciona una carrera:
        <select className="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" value={nombreTabla} onChange={handleNombreTablaChange}>
            <option value={''}>-</option>
        {tablas.map((tabla, index) => (
          <option key={index}>
            {tabla.table_name}
          </option>
        ))}
        </select>
    </label>
    <br />
    <br />

    <div className='mb-3'>
    <label for="formFileMultiple" className="form-label text-light">
        <input type="file" className='form-control' id="formFileMultiple" onChange={handleArchivoChange} />
    </label>
    </div>
    <button type="submit" className='btn btn-primary'>Cargar Excel</button>
    </form>
    <button className='btn btn-danger mt-3' onClick={handleEliminarDatos}>Eliminar Datos</button>
    </div>
  )
}

export default UpExcel