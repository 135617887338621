import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, Grid, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config/config.js'

const Forms = () => {
  const { raceName } = useParams();
  const { state } = useLocation();
  const { distance, price, image } = state || {};
  const formattedRaceName = raceName.toLowerCase().replace(/\s+/g, '_');
  const [gender, setGender] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [preferenceId] = useState(null);
  const [email, setEmail] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  
  // Inicializa Mercado Pago solo cuando el componente se monta
  useEffect(() => {
    initMercadoPago('APP_USR-72879720-3f27-4cb1-b4fd-38f4ba5edaea', { locale: 'es-AR' });
  }, []); // El array vacío asegura que se ejecuta solo una vez

  const createPreference = async (formId) => {
    try {
      const priceToPay = discountedPrice || price;
      const payerEmail = email; // Usa el email capturado del estado
      const applicationFee = priceToPay * 0.035;
      const sellerAmount = priceToPay - applicationFee;
    
      /*const response = await axios.post('http://localhost:5000/create_preference', { */
      const response = await axios.post(`${config.INSCRIPTION_URL}/create_preference`, {
        title: `Inscripción - ${distance} - ${raceName}`,
        quantity: 1,
        price: priceToPay,
        formId,
        payerEmail,      // Añadir el email del pagador
        applicationFee,  // Añadir la comisión del marketplace
        sellerAmount,
        raceName: formattedRaceName,
      });
      console.log('Respuesta de Mercado Pago:', response.data);
      const { initPoint } = response.data;
    return initPoint;
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
      alert("Hubo un error al procesar el pago. Intenta nuevamente.");
      return null;
    }
  };
/** */
  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const applyDiscount = () => {
    // Aquí puedes validar el código de descuento y aplicar el descuento si es válido
    if (discountCode === 'DESCUENTO20') { // Ejemplo de código de descuento
      setDiscountedPrice(price * 0.8); // Aplica un 20% de descuento basado en el precio original
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleShirtSizeChange = (event) => {
    setShirtSize(event.target.value); // Maneja el cambio de tallaje
    console.log("Talle seleccionado:", event.target.value);
  };

  const handleFormSubmit = async () => {
    const formId = uuidv4();
    const formData = {
      formId,
      first_name: document.getElementById('first_name').value,
      last_name: document.getElementById('last_name').value,
      document_number: document.getElementById('document_number').value,
      gender,
      birth_date: document.getElementById('birth_date').value,
      age: document.getElementById('age').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      nationality: document.getElementById('Nation').value,
      province: document.getElementById('Province').value,
      talle: shirtSize,
      running_team: document.getElementById('Team').value,
      distance,
      price,
      discount_code: discountCode,
      discounted_price: discountedPrice || price,
      raceName: formattedRaceName,
    };
    setEmail(formData.email); // Establece el correo electrónico para la preferencia
    try {
      /**await axios.post('http://localhost:5000/api/submitform', formData); */
      await axios.post(`${config.INSCRIPTION_URL}/submitform`, formData);
      console.log('Formulario enviado correctamente');
      return formId;
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      return null;
    }
  };

  const handleButtonClick = async () => {
    // Primero envía los datos del formulario
    const formId = await handleFormSubmit();
    // Luego crea la preferencia de pago
    if (formId) {
      const initPoint = await createPreference(formId);
    if (initPoint) {
      window.location.href = initPoint; // Redirige a la URL de pago de Mercado Pago
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        maxWidth: '100%',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Columna en pantallas pequeñas, fila en pantallas grandes
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, md: 4},
        borderRadius: 2,
        maxWidth: { xs: '100%', md: '80%' },
        fontFamily: 'Arial, sans-serif',
        boxShadow: "0px 1px 10px black",
        marginBottom: { xs: 2, md: 0 },
      }}
    >
      <Box
        component="img"
        src={image}
        alt="Banner"
        sx={{
          width: '100%',
          borderRadius: 2,
          marginBottom: 3,
          maxHeight: '300px',
          boxShadow: "0px 1px 10px black",
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Monserat, sans-serif', fontWeight: 600, fontSize: '1.5rem', textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'red', textDecorationThickness: '2px'}}>
        Datos del corredor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="first_name"
            label="Nombre"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="last_name"
            label="Apellido"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="document_number"
            label="Número de Documento"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="gender-label">Género</InputLabel>
            <Select
              required
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={handleGenderChange}
              label="Género"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#666',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2',
                  },
                },
              }}
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="birth_date"
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="age"
            label="Edad al día de la carrera"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            label="Correo Electrónico"
            variant="outlined"
            type="email"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            label="Teléfono"
            variant="outlined"
            type="tel"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Nation"
            label="Nacionalidad"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="Province"
            label="Provincia"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="shirt-size-label">Talle de remera</InputLabel>
                <Select
                  id='talle'
                  name="talle"
                  value={shirtSize}
                  onChange={handleShirtSizeChange}
                  label="Talle de remera"
                  required
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#666',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                  }}
                >
                  <MenuItem value="XS">XS</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="XXL">XXL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="Team"
            label="Running Team"
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': { color: '#333' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1976d2',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 4 }}>
            Código de Descuento
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="discountCode"
                label="Código"
                variant="outlined"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                sx={{
                  '& .MuiInputLabel-root': { color: '#333' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#666',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={applyDiscount}
              sx={{
                height: '100%',
                backgroundColor: 'red', // Cambia el color de fondo a rojo
                color: 'white', // Cambia el color del texto a blanco
                '&:hover': {
                  backgroundColor: 'darkred', // Cambia el color al pasar el mouse
                },
              }}
            >
              Aplicar
            </Button>
            </Grid>
          </Grid>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: 4 }}>
                Información de la inscripción en {raceName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Distancia: {distance}
              </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
                Precio: ${price}
              </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 2 }}>
            Precio Total: ${discountedPrice || price}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              backgroundColor: 'red', // Cambia el color de fondo a rojo
              color: 'white', // Cambia el color del texto a blanco
              '&:hover': {
                backgroundColor: 'darkred', // Cambia el color al pasar el mouse
              },
            }}
          >
            Finalizar
          </Button> 
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{ texts: { valueProp: 'smart_option' } }}
            />
          )}
        </Box> 
      </Box>
    </Box>
  );
};

export default Forms;
